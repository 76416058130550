/**
 * MyNotificationsState
 */
import { MyNotifications } from '@app/core/models';
import { MyNotificationsActions, MyNotificationsActionTypes } from '../actions';

export interface MyNotificationsState {
  loaded: boolean;
  pending: boolean;
  error: any;
  acknowledge: boolean;
  list: MyNotifications;
}

/**
 * MY_NOTIFICATIONS_INITIAL_STATE
 * @type {{loaded: boolean; pending: boolean; error: null; profile: null}}
 */
export const MY_NOTIFICATIONS_INITIAL_STATE: MyNotificationsState = {
  loaded: false,
  pending: false,
  error: null,
  acknowledge: false,
  list: null,
};
/**
 * brandsReducer
 * @param {MyNotificationsState} state
 * @param {MyNotificationsActions} action
 * @returns {MyNotificationsState}
 */
export const myNotificationsReducer = (
  state: MyNotificationsState = MY_NOTIFICATIONS_INITIAL_STATE,
  action: MyNotificationsActions,
): MyNotificationsState => {
  switch (action.type) {
    case MyNotificationsActionTypes.LOAD_MY_NOTIFICATIONS:
      return Object.assign({}, state, {
        ...state,
        error: null,
        pending: true,
      });
    case MyNotificationsActionTypes.LOAD_MY_NOTIFICATIONS_SUCCESS:
      console.log('success', 'success');
      return {
        ...state,
        loaded: true,
        pending: false,
        list: action.payload,
      };
    case MyNotificationsActionTypes.LOAD_MY_NOTIFICATIONS_ERROR:
      return { ...state, error: action.payload, loaded: true };
    case MyNotificationsActionTypes.PATCH_MY_NOTIFICATIONS:
      return {
        ...state,
        acknowledge: true,
      };
    default:
      return state;
  }
};
