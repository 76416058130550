import { FrameToComeActions, FrameToComeActionTypes } from '@app/core/ngrx';

/**
 * FramesCartState
 */
export interface FrameToComeState {
  loaded: boolean;
  pending: boolean;
  error: any;
  frameItems: Array<any>;
  frameAccountId: any;
  trackingNumber: any;
  cartId:any;
}

/**
 * FRAMES_CART_INITIAL_STATE
 * @type {{loaded: boolean; pending: boolean; error: null; profile: null}}
 */
export const FRAME_TO_COME_INITIAL_STATE: FrameToComeState = {
  loaded: false,
  pending: false,
  error: null,
  frameItems: [],
  trackingNumber: null,
  frameAccountId: null,
  cartId: null
};
/**
 * framesCartReducer
 * @param state
 * @param action
 */
export const frameToComeReducer = (
  state: FrameToComeState = FRAME_TO_COME_INITIAL_STATE,
  action: FrameToComeActions,
): FrameToComeState => {
  switch (action.type) {
    case FrameToComeActionTypes.LOAD_FRAME_TO_COME:
      return {
        ...state,
        error: null,
        loaded: false,
        pending: true,
        cartId: action.payload
      };
    case FrameToComeActionTypes.LOAD_FRAME_TO_COME_SUCCESS:
      return {
        ...state,
        loaded: true,
        pending: false,
        frameItems: action.payload['frameItems'],
        trackingNumber: action.payload.trackingNumber,
        frameAccountId: action.payload.frameAccountId,
        cartId: action.payload.cartId
      };
    case FrameToComeActionTypes.LOAD_FRAME_TO_COME_ERROR:
      return { ...state, frameItems: [], frameAccountId: null, trackingNumber: null, error: action.payload, loaded: false, pending:false};
    case FrameToComeActionTypes.CLEAR_FRAME_TO_COME:
      return {
        ...state,
        frameItems: [],
      };
    case FrameToComeActionTypes.ADD_FRAME_TO_COME:
      return {
        ...state,
        frameItems: [...state.frameItems, action.payload],
      };
    case FrameToComeActionTypes.REMOVE_FRAME_TO_COME:
      return {
        ...state,
        frameItems: [
          ...state.frameItems.filter((item, i) => i !== action.payload),
        ],
      };
    default:
      return state;
  }
};
