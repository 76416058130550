import { Injectable } from '@angular/core';
import { catchError, map, switchMap, withLatestFrom, filter } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';
import { BrandsService } from '@app/core/services';
import { ofType, Actions, Effect, createEffect } from '@ngrx/effects';
import { BrandsActionTypes, AuthActionTypes } from '../actions';
import { of } from 'rxjs';
import { AppState } from '@app/core/ngrx';
import { Brands } from '@app/core/models';

@Injectable()
export class BrandsEffects {
  constructor(private brandsService: BrandsService, private actions$: Actions, private store: Store<AppState>) {}

  loadBrands = createEffect(() =>
    this.actions$.pipe(
      ofType(BrandsActionTypes.LOAD_BRANDS),
      withLatestFrom(this.store.pipe(select(state => state.brands))),
      switchMap(([_, brandsFromStore]) => {
        if (brandsFromStore.loaded) {
          return of({
            type: BrandsActionTypes.LOAD_BRANDS_SUCCESS,
            payload: brandsFromStore.list,
          });
        }
        return this.brandsService.getBrands().pipe(
          map(({ brands }: Brands) => ({
            type: BrandsActionTypes.LOAD_BRANDS_SUCCESS,
            payload: brands,
          })),
          catchError(err => of({ type: BrandsActionTypes.LOAD_BRANDS_ERROR, payload: err })),
        );
      }),
    ),
  );

  // loadBrandsError = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType(BrandsActionTypes.LOAD_BRANDS_ERROR),
  //     map(() => ({ type: AuthActionTypes.LOGOUT })),
  //   ),
  // );

  loadPatientBrands = createEffect(() =>
    this.actions$.pipe(
      ofType(BrandsActionTypes.LOAD_PATIENT_BRANDS),
      withLatestFrom(this.store.pipe(select(state => state.brands.loaded))),
      filter(([_, loaded]) => !loaded),
      switchMap(([action]: [any, boolean]) => {
        return this.brandsService.getPatientBrands(action.payload.id).pipe(
          map(brands => ({
            type: BrandsActionTypes.LOAD_BRANDS_SUCCESS,
            payload: brands,
          })),
          catchError(err => of({ type: BrandsActionTypes.LOAD_BRANDS_ERROR, payload: err })),
        );
      }),
    ),
  );
}
