/**
 * MyCollectionState
 */
import { MyCollection } from '@app/core/models/my-collection.model';
import { MyCollectionActions, MyCollectionActionTypes } from '../actions';

export interface MyCollectionState {
  loaded: boolean;
  pending: boolean;
  error: any;
  list: MyCollection;
  previousList: MyCollection;
  selectedBrand: string;
  searchTerm: string;
}

/**
 * MY_COLLECTION_INITIAL_STATE
 * @type {{loaded: boolean; pending: boolean; error: null; profile: null}}
 */
export const MY_COLLECTION_INITIAL_STATE: MyCollectionState = {
  loaded: false,
  pending: false,
  error: null,
  list: null,
  previousList: null,
  selectedBrand: 'All Brands',
  searchTerm: '',
};
/**
 * brandsReducer
 * @param {MyCollectionState} state
 * @param {MyCollectionActions} action
 * @returns {MyCollectionState}
 */
export const myCollectionReducer = (
  state: MyCollectionState = MY_COLLECTION_INITIAL_STATE,
  action: MyCollectionActions,
): MyCollectionState => {
  switch (action.type) {
    case MyCollectionActionTypes.LOAD_MY_COLLECTION:
      return {
        ...state,
        error: null,
        pending: true,
      };
    case MyCollectionActionTypes.LOAD_MY_COLLECTION_SUCCESS:
      return {
        ...state,
        loaded: true,
        pending: false,
        list: action.payload,
        previousList: action.payload
      };
    case MyCollectionActionTypes.LOAD_MY_COLLECTION_ERROR:
      return { ...state, error: action.payload };
    case MyCollectionActionTypes.SET_MY_COLLECTION_SELECTED_BRAND:
      return {
        ...state,
        selectedBrand: action.payload,
      };

    case MyCollectionActionTypes.REVERT_RETAIL_PRICE_CHANGES:
      return {
        ...state,
        list: state.previousList
      };
    case MyCollectionActionTypes.SAVE_ALL_RETAIL_PRICE_CHANGES:
      return {
        ...state,
        previousList: state.list
      };
    case MyCollectionActionTypes.SEARCH_MY_COLLECTION:
      return {
        ...state,
        searchTerm: action.payload,
      };
    case MyCollectionActionTypes.UPDATE_RETAIL_PRICE: {
      const { brandId, moduleId, clusterId, frames, retailPricingFormula } = action.payload;
    
      // Validate if the brand exists
      const brandIndex = state.list.brands.findIndex(b => b.brandId === brandId);
      if (brandIndex === -1) {
        console.error(`Invalid brandId (${brandId}) in UPDATE_RETAIL_PRICE action`);
        return state; // Return the state unchanged
      }

      const selectedBrand = state.list.brands[brandIndex];

      if (selectedBrand.hasClusters) {
        const clusterIndex = selectedBrand.clusters.findIndex(cluster => cluster.clusterId === clusterId);
        if (clusterIndex === -1) {
          console.error(`Invalid clusterId (${clusterId}) in UPDATE_RETAIL_PRICE action`);
          return state; // Return the state unchanged
        }

        // Using slice to reconstruct the brands array
        return {
          ...state,
          list: {
            ...state.list,
            brands: state.list.brands.map((brandItem, index) => {
              if (index === brandIndex) {
                return {
                  ...brandItem,
                  clusters: brandItem.clusters.map((cluster, idx) =>
                    idx === clusterIndex ? { ...cluster, frames, retailPricingFormula } : cluster,
                  ),
                };
              }
              return brandItem;
            }),
          },
        };
      }

      // Handle brands with modules (no clusters)
      const moduleIndex = selectedBrand.modules.findIndex(module => module.moduleId === moduleId);
      if (moduleIndex === -1) {
        console.error(`Invalid moduleId (${moduleId}) in UPDATE_RETAIL_PRICE action`);
        return state; // Return the state unchanged
      }

      // Using slice to reconstruct the brands array
      return {
        ...state,
        list: {
          ...state.list,
          brands: state.list.brands.map((brandItem, index) => {
            if (index === brandIndex) {
              return {
                ...brandItem,
                modules: brandItem.modules.map((module, idx) => (idx === moduleIndex ? { ...module, frames, retailPricingFormula } : module)),
              };
            }
            return brandItem;
          }),
        },
      };
    }

    default:
      return state;
  }
};
