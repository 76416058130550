import { Component, HostBinding, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { trigger, transition, useAnimation } from '@angular/animations';
import { MatDialog } from '@angular/material/dialog';
import { slideInAnimation, slideOutAnimation } from '@app/shared/animations';
import { ConfirmDialogComponent } from '../../../../shared/components/dialogs/confirm-dialog/confirm-dialog.component';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'ecp-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss'],
  animations: [
    trigger('visibilityChange', [
      transition(':enter', [
        useAnimation(slideInAnimation, {
          params: { timings: '400ms ease-out', from: '20%' },
        }),
      ]),

      transition(':leave', [
        useAnimation(slideOutAnimation, {
          params: { timings: '400ms ease-out', to: '20%' },
        }),
      ]),
    ]),
  ],
})
export class NavComponent implements OnInit {
  @HostBinding('class.nav') nav_ = true;

  @HostBinding('class.to-right') to_right;
  @Input()
  toRight: boolean;
  isHidden = false;
  selectedLocation: string;
  @Input() ecpData: any = null;
  @HostBinding('@visibilityChange') animation = true;
  @Output() locationSelect = new EventEmitter<any>();
  constructor(public dialog: MatDialog, public router: Router) {
    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((res: any) => {
      if (res.url.indexOf('/bulk-order') > -1) {
        this.isHidden = true;
      } else {
        this.isHidden = false;
      }
    });
  }

  ngOnInit() {
    this.selectedLocation = this.ecpData.defaultLoc.toLowerCase();
  }

  switchLocation(ev: any) {
    this.dialog
      .open(ConfirmDialogComponent, {
        data: {
          title: 'Profile Switch',
          content: `Are you sure you would like to switch the location of your account?`,
          cancelBtnText: 'Cancel',
          confirmBtnText: 'Confirm',
        },
        disableClose: true,
      })
      .afterClosed()
      .subscribe(async resp => {
        if (resp) {
          this.locationSelect.emit(ev.value);
        } else {
          this.ecpData.accountLocation = this.ecpData.defaultLoc;
        }
      });
  }
}
