<ng-content select="ecp-nav-item"></ng-content>
<!-- Location Dropdown for Super User -->
<ng-container *ngIf="ecpData?.ecpRole == 'superuser' && !isHidden">
  <mat-form-field style="margin-top: 10px;">
    <mat-select [(value)]="selectedLocation" (selectionChange)="switchLocation($event)">
      <mat-option *ngFor="let ecpLoc of ecpData.ecpLocations" [value]="ecpLoc">
        {{ ecpLoc }}
      </mat-option>
    </mat-select>
  </mat-form-field>
</ng-container>
