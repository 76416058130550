import { Brands } from '@app/core/models';
import { BrandsActions, BrandsActionTypes } from '../actions';

/**
 * BrandsState
 */
export interface BrandsState {
  loaded: boolean;
  pending: boolean;
  error: any;
  list: Brands;
}

/**
 * BRANDS_INITIAL_STATE
 * @type {{loaded: boolean; pending: boolean; error: null; profile: null}}
 */
export const BRANDS_INITIAL_STATE: BrandsState = {
  loaded: false,
  pending: false,
  error: null,
  list: null,
};
/**
 * brandsReducer
 * @param {BrandsState} state
 * @param {BrandsActions} action
 * @returns {BrandsState}
 */
export const brandsReducer = (state: BrandsState = BRANDS_INITIAL_STATE, action: BrandsActions): BrandsState => {
  switch (action.type) {
    case BrandsActionTypes.LOAD_BRANDS:
      return {
        ...state,
        error: null,
        pending: true,
      };
    case BrandsActionTypes.LOAD_BRANDS_SUCCESS:
      return {
        ...state,
        loaded: true,
        pending: false,
        list: action.payload,
      };
    case BrandsActionTypes.LOAD_BRANDS_ERROR:
      return { ...state, error: action.payload };
    default:
      return state;
  }
};
