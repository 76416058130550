import { Component, OnInit, Inject } from '@angular/core';
import { State, Store, select } from '@ngrx/store';
import { Observable } from 'rxjs/internal/Observable';
import { AppState, selectProgram } from '@app/core/ngrx';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Validators } from '@angular/forms';

import { VWProgram } from '@app/core/models';
import { SearchService } from '@app/core/services/search.service';
import { ExportBrandPdfService } from '@app/core/services/export-brand-pdf.service';

@Component({
  selector: 'ecp-export-brand',
  templateUrl: './export-brand.component.html',
  styleUrls: ['./export-brand.component.scss'],
})
export class ExportBrandComponent implements OnInit {
  public brands$: Observable<any>;
  public program$: Observable<VWProgram>;

  theme = 'one-portal';
  isExportingBrand: boolean;
  constructor(
    private store: Store<AppState>,
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<ExportBrandComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private searchService: SearchService,
    private exportBrandPdfService: ExportBrandPdfService,
    private state: State<AppState>,
  ) {}

  exportBrandForm: FormGroup;

  ngOnInit(): void {
    this.program$ = this.store.pipe(select(selectProgram));
    this.brands$ = this.store.pipe(select(state => state.brands.list));

    this.program$.pipe().subscribe(program => {
      if (program) {
        this.theme = program.programKey;
      }
    });
    this.exportBrandForm = this.fb.group({
      exportSelectedBrand: ['', Validators.required],
    });
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  exportBrand() {
    this.isExportingBrand = true;
    this.searchService
      .exportBrandPlanogram({
        brands: [this.exportBrandForm.value.exportSelectedBrand],
        terms: [],
        sizes: [],
        skip: 0,
      })
      .subscribe(frames => {
        let framesList = frames.frames;
        let programKeyState = this.state.getValue().user.profile.program.programKey;
        this.exportBrandPdfService
          .exportBrandProcess(framesList, programKeyState, this.exportBrandForm.value.exportSelectedBrand)
          .subscribe(resp => {
            this.isExportingBrand = false;
            this.closeDialog();
          });
      });
  }
}
