import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap, takeUntil, withLatestFrom } from 'rxjs/operators';

import { Router } from '@angular/router';
import { MyNotificationsService } from '@app/core/services';
import { Actions, Effect, ofType, createEffect } from '@ngrx/effects';
import { MyNotificationsActionTypes, AuthActionTypes } from '../actions';
import { Store } from '@ngrx/store';
import { AppState } from '..';

/**
 * MyNotificationsEpics
 */
@Injectable()
export class MyNotificationsEffects {
  /**
   * constructor
   * @param {MyNotificationsService} myNotificationsService
   */
  constructor(
    private myNotificationsService: MyNotificationsService,
    private actions$: Actions,
    private router: Router,
    private store: Store<AppState>,
  ) {}

  /**
   * loadMyNotifications
   * @param {ActionsObservable<any>} action$
   * @returns {Observable<any>}
   */
  loadMyNotifications = createEffect(() =>
    this.actions$.pipe(
      ofType(MyNotificationsActionTypes.LOAD_MY_NOTIFICATIONS),
      withLatestFrom(this.store.select(state => state.myNotifications)),
      switchMap(([_, notificationsFromStore]) => {
        if (notificationsFromStore.loaded) {
          return of({
            type: MyNotificationsActionTypes.LOAD_MY_NOTIFICATIONS_SUCCESS,
            payload: notificationsFromStore.list,
          });
        }

        return this.myNotificationsService.getMyNotifications().pipe(
          map(notifications => ({
            type: MyNotificationsActionTypes.LOAD_MY_NOTIFICATIONS_SUCCESS,
            payload: notifications,
          })),
          catchError(err =>
            of({
              type: MyNotificationsActionTypes.LOAD_MY_NOTIFICATIONS_ERROR,
              payload: err,
            }),
          ),
        );
      }),
    ),
  );

  /**
   * loadMyNotificationsError
   * @param {ActionsObservable<any>} action$
   * @returns {Observable<any>}
   */
  loadMyNotificationsError = createEffect(() =>
    this.actions$.pipe(
      ofType(MyNotificationsActionTypes.LOAD_MY_NOTIFICATIONS_ERROR),
      map(() => ({ type: AuthActionTypes.LOGOUT })),
    ),
  );
}
